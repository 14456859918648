import { AddAndUpdateAnalyzedSlag } from '@app/api/heats';
import { hasAlphaFeaturesPermission, text2Subscript } from '@app/utils';
import { Box, makeStyles } from '@material-ui/core';
import FinalAnalyzedSlagModal from '../modals/FinalAnalyzedSlagModal';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { EditOutlined } from '@material-ui/icons';
import { useState } from 'react';
import { pascalize } from 'humps';
import { useTranslation } from 'react-i18next';
import { APP_DESULFURIZATION_RESULTS_ENABLED, APP_NA2O_K2O_ENABLED, CENTIMETERS_METERS_CONVERSION_FACTOR, NOT_APPLICABLE_TEXT } from '@app/utils/constants';
import { CalculatedSlagInfo, DesulfurizationResults } from '@app/api/heats/HeatsResponse';
import WarningMessageComponent from '@app/components/shared/WarningMessageComponent';
import { ReportProblemIcon } from '@app/assets/icons/reportProblemIcon';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import FormButton from '@app/components/shared/FormButton';
import useReturnUnitOfMeasurement from '@app/hooks/useReturnUnitOfMeasurement';
import { SystemOfMeasurementOptions } from '@app/api/me/MeGetResponse';

const useStyles = makeStyles(theme => ({
  root: { width: '100%', overflowX: 'auto', flexGrow: 1 },
  menuButton: { float: 'left' },
  textButton: { float: 'left' },
  table: { minWidth: 650 },
  wrapper: { position: 'relative' },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  listItem: {
    background: 'white',
    border: '1px solid #ccc',
    width: '44vw',
    lineHeight: '10px',
  },
  listTextBase: {
    margin: 0,
    padding: '16px 6px',
    height: '100%',
    width: '100%'
  },
  listTextTitle: {
    margin: 0,
    padding: '16px 6px',
    height: '100%',
    width: '100%',
    fontWeight: 'bold'
  }
}));

function convertToArray(obj?: {
  mgO: number;
  caO: number;
  al2O3: number;
  siO2: number;
  feO: number;
  mnO: number;
  cr2O3: number;
  tiO2: number;
  caF2: number;
  na2O: number;
  k2O: number;
}) {
  if (!obj) return [];
  return (Object.keys(obj) as (keyof typeof obj)[]).filter((_, index) => APP_NA2O_K2O_ENABLED || index <= 8).map(k => ({ name: pascalize(k), weight: obj[k] }));
}

type NumberOrNullOrUndefined = number | null | undefined;

interface Step6Props {
  disabled?: boolean;
  initialSlag?: CalculatedSlagInfo,
  originalSlag?: CalculatedSlagInfo,
  modifiedRecommendations?: CalculatedSlagInfo | null,
  analyzedSlag?:  AddAndUpdateAnalyzedSlag | null,
  submitAnalyzedSlag?: (slags: AddAndUpdateAnalyzedSlag) => Promise<void>,
}

function Step6({ disabled = false, initialSlag, originalSlag, analyzedSlag, modifiedRecommendations, submitAnalyzedSlag }: Step6Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { state: { userInfoObject } } = useAuthenticationContext();

  const userInfo = userInfoObject?.data;

  const [analyzedSlagModal, setAnalyzedSlagModal] = useState(false);

  const showDesulfurizationResults = APP_DESULFURIZATION_RESULTS_ENABLED && (hasAlphaFeaturesPermission(userInfo));

  const finalSlag = modifiedRecommendations || originalSlag;

  const formatValue = (value: NumberOrNullOrUndefined, decimalPrecision: number, round: boolean, suffix?: string, multiplicationFactor?: number) : string => {
    if (value === null || value === undefined) {
      return NOT_APPLICABLE_TEXT;
    }

    if (round) {
      value = Math.round(value);
    }

    if (multiplicationFactor) {
      value = value * multiplicationFactor;
    }

    let formattedValue = value.toFixed(decimalPrecision);

    if (suffix) {
      formattedValue = `${formattedValue} ${suffix}`;
    }

    return formattedValue;
  }

  const { returnLengthUnitOfMeasurement, returnWeightUnitOfMeasurement } = useReturnUnitOfMeasurement();
  const measurementSystem = userInfo?.measurementSystem;

  const formatSlagCompositionPercentage = (value: NumberOrNullOrUndefined) : string => {
    return formatValue(value, 2, false, '%');
  }

  const formatSlagWeight = (value: NumberOrNullOrUndefined) : string => {
    return formatValue(value, 0, true, returnWeightUnitOfMeasurement(measurementSystem, "lb"));
  }

  const formatSlagDepth = (value: NumberOrNullOrUndefined) : string => {
    return formatValue(
      value,
      2,
      false,
      returnLengthUnitOfMeasurement(measurementSystem, "cm"),
      measurementSystem === SystemOfMeasurementOptions.Imperial
        ? 1
        : CENTIMETERS_METERS_CONVERSION_FACTOR
    );
  }

  const formatDesulfurizationResult = (value: NumberOrNullOrUndefined, decimalPrecision: number) : string => {
    return formatValue(value, decimalPrecision, false);
  }

  const renderHeader = () => {
    return (
      <Grid item container className={classes.listItem}>
        <Grid item sm={2}>
          <Typography variant="body2" className={classes.listTextTitle}>{t('composition')}</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography variant="body2" className={classes.listTextTitle} style={{ textAlign: 'right' }}>{t('new-heat.form.title.start-slag')}</Typography>
        </Grid>
        <Grid item sm={4}>
          <Typography variant="body2" className={classes.listTextTitle} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{t('new-heat.form.title.original-recommendations')}</Typography>
        </Grid>
        <Grid item sm={3}>
          <Typography variant="body2" className={classes.listTextTitle} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{t('new-heat.form.title.end-slag')}</Typography>
        </Grid>
      </Grid>
    );
  }

  const renderSlagComposition = (initial?: CalculatedSlagInfo, original?: CalculatedSlagInfo, final?: CalculatedSlagInfo) => {
    const referenceSlag = initial || original;
    const initialArray = convertToArray(initial?.slags);
    const originalArray = convertToArray(original?.slags);
    const finalArray = convertToArray(final?.slags);
    const elements = convertToArray(referenceSlag?.slags).map((row, i: number) => {
      return {
        index: i,
        name: row.name,
      }
    });

    return (
      <>
        {elements.map(element => (
          <Grid item container className={classes.listItem} key={element.index}>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase}>{text2Subscript(element.name)}</Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatSlagCompositionPercentage(initialArray[element.index]?.weight)}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatSlagCompositionPercentage(originalArray[element.index]?.weight)}</Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatSlagCompositionPercentage(finalArray[element.index]?.weight)}</Typography>
            </Grid>
          </Grid>
        ))}
      </>
    );
  }

  const renderSlagWeightAndDepth = (initial?: CalculatedSlagInfo, original?: CalculatedSlagInfo, final?: CalculatedSlagInfo) => {
    const renderWeight = initial?.weight || original?.weight;
    const renderDepth = initial?.depth || original?.depth;

    return (
      <>
        {renderWeight ? (
          <Grid item container className={classes.listItem}>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase} >{t('weight')}</Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatSlagWeight(initial?.weight)}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatSlagWeight(original?.weight)}</Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatSlagWeight(final?.weight)}</Typography>
            </Grid>
          </Grid>
        ) : null}

        {renderDepth ? (
          <Grid item container className={classes.listItem}>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase} >{t('new-heat.form.list-item.slag-height')}</Typography>
            </Grid>
            <Grid item sm={2}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatSlagDepth(initialSlag?.depth)}</Typography>
            </Grid>
            <Grid item sm={4}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatSlagDepth(original?.depth)}</Typography>
            </Grid>
            <Grid item sm={3}>
              <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatSlagDepth(final?.depth)}</Typography>
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  }

  const renderDesulfurizationResults = (initial?: DesulfurizationResults, original?: DesulfurizationResults, final?: DesulfurizationResults) => {
    const hasDesulfurizationResults = initial?.desulfurizationOption !== 'None' && original?.desulfurizationOption !== 'None' && final?.desulfurizationOption !== 'None';
    const hasAnyOxidizedSlag = hasDesulfurizationResults && (initial?.oxidizedSlag || original?.oxidizedSlag || final?.oxidizedSlag);
    const showWarning = !hasDesulfurizationResults || hasAnyOxidizedSlag;

    return (
      <>
        <Grid item container className={classes.listItem}>
          <Grid item sm={12}>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant='body2' className={classes.listTextTitle} style={{ textAlign: 'center', width: 'auto' }}>{t('calculated-slag.desulfurization-results.section-name')}</Typography>
              {showWarning && <ReportProblemIcon noMargin size='24px' />}
            </Box>
          </Grid>
        </Grid>
        <Grid item container className={classes.listItem}>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} >{t('calculated-slag.desulfurization-results.optical-basicity')}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatDesulfurizationResult(initial?.opticalBasicity, 3)}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatDesulfurizationResult(original?.opticalBasicity, 3)}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatDesulfurizationResult(final?.opticalBasicity, 3)}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.listItem}>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} >{t('calculated-slag.desulfurization-results.sulfide-capacity')}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatDesulfurizationResult(initial?.sulfideCapacity, 3)}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatDesulfurizationResult(original?.sulfideCapacity, 3)}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatDesulfurizationResult(final?.sulfideCapacity, 3)}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.listItem}>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} >{t('calculated-slag.desulfurization-results.sulfur-distribution-ratio')}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatDesulfurizationResult(initial?.sulfurDistributionCoefficient, 2)}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatDesulfurizationResult(original?.sulfurDistributionCoefficient, 2)}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatDesulfurizationResult(final?.sulfurDistributionCoefficient, 2)}</Typography>
          </Grid>
        </Grid>
        <Grid item container className={classes.listItem}>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} >{t('calculated-slag.desulfurization-results.final-sulfur-percentage')}</Typography>
          </Grid>
          <Grid item sm={2}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>{formatDesulfurizationResult(initial?.finalSulfurPercentage, 4)}</Typography>
          </Grid>
          <Grid item sm={4}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#F9F9F9' }}>{formatDesulfurizationResult(original?.finalSulfurPercentage, 4)}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right', backgroundColor: '#ECECEC' }}>{formatDesulfurizationResult(final?.finalSulfurPercentage, 4)}</Typography>
          </Grid>
        </Grid>
        {showWarning && renderDesulfurizationResultsWarning(hasDesulfurizationResults, hasAnyOxidizedSlag)}
      </>
    );
  }

  const renderDesulfurizationResultsWarning = (hasDesulfurizationResults: boolean, hasAnyOxidizedSlag: boolean | undefined) => {
    return (
      <Grid item container className={classes.listItem} style={{ marginTop: '25px' }}>
        {!hasDesulfurizationResults && <WarningMessageComponent message={t('calculated-slag.desulfurization-results.no-result-warning')} />}
        {hasAnyOxidizedSlag && <WarningMessageComponent message={<>{t('calculated-slag.desulfurization-results.oxidized-slag-warning')}{' ('}{text2Subscript('FeO + MnO + Cr2O3')}{' > 3%).'}</>} />}
      </Grid>
    );
  }

  const renderFinalAnalysedSlag = () => {
    if(!analyzedSlag){
      return (
        <Grid>
          {disabled &&
            (
              <>
                <Grid item container justifyContent="center" direction="row" alignItems="center" style={{ margin: '16px 0', height: 50 }}>
                  <Typography variant="body2"
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}>
                    {t('new-heat.form.title.final-analyzed-slag')}
                  </Typography>
                </Grid>
                <Typography variant="body2"
                  style={{
                    margin: '16px 0',
                    textAlign: 'left',
                  }}>
                  {t('new-heat.form.title.final-analysed-slag-empty-state')}
                </Typography>
                <Grid item container justifyContent="center">
                  <FormButton variant="primary" onClick={() => setAnalyzedSlagModal(true)}>
                    {t('new-heat.form.title.final-analysed-slag-button-text')}
                  </FormButton>
                </Grid>
              </>
            )
          }
          <Typography variant="body2"
            style={{
              marginTop: 35,
              textAlign: 'center',
              fontWeight: 'bold'
            }}>
            {t('new-heat.form.title.subtitles')}
          </Typography>
          <Typography variant="body2"
            style={{
              marginTop: 15,
              fontWeight: 'bold'
            }}>
            {t('new-heat.form.title.start-slag')}
          </Typography>
          <Typography variant="body2">
            {t('new-heat.form.title.initial-slag-text')}
          </Typography>
          <Typography variant="body2"
            style={{
              marginTop: 15,
              fontWeight: 'bold'
            }}>
            {t('new-heat.form.title.original-recommendations')}
          </Typography>
          <Typography variant="body2">
            {t('new-heat.form.title.originalRecommendations-text')}
          </Typography>
          <Typography variant="body2"
            style={{
              marginTop: 15,
              fontWeight: 'bold'
            }}>
            {t('new-heat.form.title.end-slag')}
          </Typography>
          <Typography variant="body2">
            {t('new-heat.form.title.final-slag-text')}
          </Typography>
        </Grid>
      );
    }

    return (
      <>
        <Grid item container justifyContent="center" direction="row" alignItems="center" style={{ margin: '16px 0', height: 50, width: '20vw' }}>
          <Grid item container justifyContent="flex-end" sm={9} xl={8}>
            <Typography variant="body2"
              style={{
                textAlign: 'center',
                fontWeight: 'bold'
              }}>
              {t('new-heat.form.title.final-analyzed-slag')}
            </Typography>
          </Grid>
          {analyzedSlag &&
            <Grid item container justifyContent="flex-end" sm={3} xl={4}>
              <FormButton
                variant="primary"
                size="small"
                onClick={() => setAnalyzedSlagModal(true)}
                style={{ padding: 0, marginLeft: 8, minWidth: 30, minHeight: 30 }}
              >
                <EditOutlined fontSize="small" />
              </FormButton>
            </Grid>
          }
        </Grid>
        <ListItem
          style={{
            background: 'white',
            border: '1px solid #ccc',
            width: '20vw',
            lineHeight: '10px',
            padding: '0 6px'
          }}
        >
          <Typography variant="body2" className={classes.listTextBase}>{t('composition')}</Typography>
          <Typography variant="body2" className={classes.listTextBase} style={{ textAlign: 'right' }}>
            {t('new-heat.form.list-item.analyzed-slag')}
          </Typography>
        </ListItem>
        {Object.keys(analyzedSlag)?.filter((row: string) => APP_NA2O_K2O_ENABLED || !['na2O', 'k2O'].includes(row)).map((row: string, i: number) => (
          <ListItem
            key={i}
            style={{
              background: 'white',
              border: '1px solid #ccc',
              width: '20vw',
              lineHeight: '10px',
              padding: '0 6px'
            }}
          >
            <Typography variant="body2" className={classes.listTextBase}>{text2Subscript(pascalize(row))}</Typography>
            <Typography
              variant="body2"
              className={classes.listTextBase}
              style={{ textAlign: 'right' }}
            >
              {
                parseFloat(
                  '0' +
                  (
                    APP_NA2O_K2O_ENABLED
                    ? analyzedSlag[row as 'caO' | 'al2O3' | 'siO2' | 'feO' | 'mnO' | 'cr2O3' | 'tiO2' | 'caF2' | 'na2O' | 'k2O']
                    : analyzedSlag[row as 'caO' | 'al2O3' | 'siO2' | 'feO' | 'mnO' | 'cr2O3' | 'tiO2' | 'caF2']
                  ).toString()).toFixed(2)
              } %
            </Typography>
          </ListItem>
        ))}
      </>
    );
  }

  return (
    <Grid container style={{ maxWidth: '99%', marginLeft: '0px', marginTop: '25px' }}>
      {analyzedSlagModal && (
        <FinalAnalyzedSlagModal
          onClose={() => setAnalyzedSlagModal(false)}
          onConfirm={(slags: AddAndUpdateAnalyzedSlag) => {
            if(submitAnalyzedSlag) {submitAnalyzedSlag(slags)}
          }}
          slags={analyzedSlag as AddAndUpdateAnalyzedSlag}
        />
      )}
      <Grid item sm={8} style={{ background: '#F7F7F7' }}>
        <Grid item container justifyContent="center" direction="row" alignItems="center" style={{ margin: '16px 0', height: 50 }}>
          <Typography variant="body2" style={{ textAlign: 'center', fontWeight: 'bold' }}>{t('calculated-slags')}</Typography>
        </Grid>
        <Grid item container justifyContent="center" style={{ marginBottom: 30 }}>
          {renderHeader()}
          {renderSlagComposition(initialSlag, originalSlag, finalSlag)}
          {renderSlagWeightAndDepth(initialSlag, originalSlag, finalSlag)}
          {showDesulfurizationResults && renderDesulfurizationResults(initialSlag?.desulfurizationResults, originalSlag?.desulfurizationResults, finalSlag?.desulfurizationResults)}
        </Grid>
      </Grid>

      <Grid item sm={4} style={{ background: '#ECECEC' }}>
        <Grid item container justifyContent="center" style={{ marginBottom: 30, padding: '0 16px' }}>
          {renderFinalAnalysedSlag()}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Step6;
