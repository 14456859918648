import { useTranslation } from 'react-i18next';
import { sectionsImages } from '../overviewUtils';
import OverviewStatusChip from "./OverviewStatusChip";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  createStyles,
  makeStyles,
  Avatar,
  withStyles,
  Theme
} from '@material-ui/core';
import { ROUTES_PATH } from '@app/utils';
import PageTitle from '@app/components/PageTitle';
import { Link, useHistory } from 'react-router-dom';
import { PERMISSIONS } from '@app/utils/constants';
import { usePlantContext } from '@app/store/plantValidationContext/plantValidationContext'

import CustomTooltip from '@app/components/shared/CustomTooltip'
import { ADDITIONS, CARRYOVERSLAGS, EQUIPMENT } from '@app/utils/plantSetupRoutesPathTabs';
import { useAuthenticationContext } from '@app/store/authentication/authenticationContext';
import { useEffect, useRef, useState } from 'react';
import ConfirmDialog from '@app/components/shared/ConfirmDialog';
import useCallApi from '@app/hooks/useCallApi';
import { useMeService } from '@app/api/me';
import { UserNotificationType } from '@app/api/me/UserNotificationResponse';
import { PlantStatus } from '@app/api/plants/PlantsGetResponse';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      padding: '0px 0px 24px 24px'
    },
    headerActions: {
      display: 'flex',
      alignItems: 'center',

      '& > :not(:first-child)': {
        marginLeft: 8,
      }
    },
    content: {
      overflowY: 'auto',
      backgroundColor: '#fff',
      backgroundRepeat: 'no-repeat',
      backgroundAttachment: 'local, local, scroll, scroll',
      backgroundSize: '100% 15px, 100% 15px, 100% 5px, 0',
      padding: '8px 24px 24px 24px'
    },
    cardSize: {
      height: 'fit-content',
      display: 'flex',
      padding: '16px',
      gap: '16px',
      flexDirection: 'column',
      flex: 1,
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)'
    },
    cardHeader: {
      padding: 0
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      columnGap: 1,
      width: '100%',
      padding: '0 !important'
    },
    headerTitle: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '20px'
    },
    subheader: {
      fontSize: '12px',
      lineHeight: '13px',
      color: '#929898'
    },
  })
);

interface OverviewHeaderTitleProps {
  title: React.ReactNode;
}

const OverviewHeaderTitle = ({ title }: OverviewHeaderTitleProps) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {typeof title === 'string' ? <PageTitle title={title} /> : title}
    </Box>
  );
};

const Footer = withStyles((theme: Theme) => ({
  root: {
    padding: "10px 16px",
    boxSizing: "border-box",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    columnGap: "16px",
    rowGap: "8px",
    background: theme.palette.common.white,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.3)",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px"
  },
}))(Box);

const boxesStyles: React.CSSProperties = {
  display: 'flex',
  flex: 1,
  minHeight: '100%'
}

export interface PlantNavigationState {
  alreadyUsed: boolean, 
  expired: boolean, 
  status: PlantStatus[],  
}

const Overview = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { hasAccess, state: { userInfoObject } } = useAuthenticationContext();
  const { state: plantState } = usePlantContext();
  const { getNotifications } = useMeService();
  const [notificationsResult, callNotifications] = useCallApi(getNotifications, []);
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

  const headerRef = useRef<HTMLHeadingElement>(null);
  const classes = useStyles();
  const ladleFurnaceHeatTooltipMessage = plantState.data.general ? '' : t('overview.tooltip.new-heat', { heatMode: t('calculation.mode.ladlefurnace') })
  const tappingHeatTooltipMessage = plantState.data.general ? '' : t('overview.tooltip.new-heat', { heatMode: t('calculation.mode.tapping') })

  useEffect(() => {
    if (userInfoObject?.status === 'succeeded' && notificationsResult.status === 'idle') {
      callNotifications();
    }
  }, [userInfoObject?.status, notificationsResult.status, callNotifications]);

  useEffect(() => {
    if (notificationsResult.status === 'succeeded' && notificationsResult.data) {
      setOpenConfirmationModal(notificationsResult.data.some(
        notification => notification.type === UserNotificationType.ExpiredPlants));
    }
  }, [notificationsResult.status, notificationsResult.data]);

  const redirectToPlantManagement = () => {
    const plantNavigationState: PlantNavigationState = {
      expired: true, 
      status: [PlantStatus.ACTIVE, PlantStatus.TRIAL], 
      alreadyUsed: false 
    };

    history.push({ 
      pathname: ROUTES_PATH.USERS_PLANT_MANAGEMENT, 
      state: plantNavigationState
    });
  }

  return (
    <>
      <ConfirmDialog
        visible={openConfirmationModal}
        setVisible={setOpenConfirmationModal}
        title={t('notification.expired-plants.title')}
        content={t('notification.expired-plants.content')}
        cancelText={t('button.close')}
        cancelAction={() => setOpenConfirmationModal(false)}
        confirmText={t('notification.expired-plants.confirm-text')}
        confirmAction={redirectToPlantManagement} />

      <Card
        style={{
          flex: 1,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          overflow: 'initial'
        }}
      >
        <CardHeader
          className={classes.header}
          ref={headerRef}
          title={<OverviewHeaderTitle title={t('overview.header.title')} />}
          subheader={t('overview.subheader.title')}
        />

        <CardContent
          className={classes.content}
        >
          <Box
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '10px',
              flex: 1,
            }}
          >
            <Box style={boxesStyles}>
              <Card className={classes.cardSize} variant='outlined'>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<Avatar src={sectionsImages.equipment} ></Avatar>}
                  title={<span className={classes.headerTitle}>{t('overview.equipment.title')}</span>}
                  subheader={<span className={classes.subheader}>{t('overview.equipment.subtitle')}</span>}
                />
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                  <OverviewStatusChip
                    linkId="overview.card.link.ladle-furnace"
                    title={t('ladlefurnace')}
                    isValid={plantState.data.equipment.ladleFurnace}
                    url={ROUTES_PATH.PLANT_EQUIPMENT}
                    state={{ option: EQUIPMENT.LADLE_FURNACE }}
                  ></OverviewStatusChip>
                  <OverviewStatusChip
                    linkId="overview.card.link.ladle"
                    title={t('ladle')}
                    isValid={plantState.data.equipment.ladle}
                    url={ROUTES_PATH.PLANT_EQUIPMENT}
                    state={{ option: EQUIPMENT.LADLE }}
                  ></OverviewStatusChip>
                </CardContent>
              </Card>
            </Box>

            <Box style={boxesStyles}>
              <Card className={classes.cardSize} variant='outlined'>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<Avatar src={sectionsImages.additions} ></Avatar>}
                  title={<span className={classes.headerTitle}>{t('overview.additions.title')}</span>}
                  subheader={<span className={classes.subheader}>{t('overview.additions.subtitle')}</span>}
                />
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                  <OverviewStatusChip
                    linkId="overview.card.link.fluxes"
                    title={t('fluxes')}
                    isValid={plantState.data.additions.fluxes}
                    url={ROUTES_PATH.PLANT_ADDITIONS}
                    state={{ option: ADDITIONS.FLUXES }}
                  ></OverviewStatusChip>
                  <OverviewStatusChip
                    linkId="overview.card.link.alloys"
                    title={t('alloys')}
                    isValid={plantState.data.additions.alloys}
                    url={ROUTES_PATH.PLANT_ADDITIONS}
                    state={{ option: ADDITIONS.ALLOYS }}
                  ></OverviewStatusChip>
                  <OverviewStatusChip
                    linkId="overview.card.link.slag-deoxidizers"
                    title={t('slag-deoxidizers')}
                    isValid={plantState.data.additions.slagDeoxidizers}
                    url={ROUTES_PATH.PLANT_ADDITIONS}
                    state={{ option: ADDITIONS.SLAG_DEOXIDIZERS }}
                  ></OverviewStatusChip>
                </CardContent>
              </Card>
            </Box>

            <Box style={boxesStyles}>
              <Card className={classes.cardSize} variant='outlined'>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<Avatar src={sectionsImages.carryoverSlag} ></Avatar>}
                  title={<span className={classes.headerTitle}>{t('carryover-slags')}</span>}
                  subheader={<span className={classes.subheader}>{t('overview.carryover-slag.subtitle')}</span>}
                />
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                  <OverviewStatusChip
                    linkId="overview.card.link.inference-modal"
                    title={t('inference')}
                    isValid={plantState.data.carryoverSlag.inference}
                    url={ROUTES_PATH.SETTINGS_CARRYOVER_SLAGS}
                    state={{ option: CARRYOVERSLAGS.INFERENCE }}
                  ></OverviewStatusChip>
                  <OverviewStatusChip
                    linkId="overview.card.link.composition"
                    title={t('composition')}
                    isValid={plantState.data.carryoverSlag.composition}
                    url={ROUTES_PATH.SETTINGS_CARRYOVER_SLAGS}
                    state={{ option: CARRYOVERSLAGS.COMPOSITION }}
                  ></OverviewStatusChip>
                </CardContent>
              </Card>
            </Box>

            <Box style={boxesStyles}>
              <Card className={classes.cardSize} variant='outlined'>
                <CardHeader
                  className={classes.cardHeader}
                  avatar={<Avatar src={sectionsImages.steelGrade} ></Avatar>}
                  title={<span className={classes.headerTitle}>{t('steel-grade')}</span>}
                  subheader={<span className={classes.subheader}>{t('steel-grade-category')}</span>}
                />
                <CardContent className={classes.cardContent} style={{ paddingBottom: '8px' }}>
                  <OverviewStatusChip
                    linkId="overview.card.link.steel-grade"
                    title={t('steel-grade-category')}
                    isValid={plantState.data.steelGrade.category}
                    url={ROUTES_PATH.APPLICATIONS_STEEL_GRADE}
                  ></OverviewStatusChip>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </CardContent>
      </Card>
     { hasAccess([PERMISSIONS.BASIC_ACCESS_WRITE]) && (
        <Footer>
          <CustomTooltip
            key='plant-overview-new-heat-tapping'
            title={tappingHeatTooltipMessage}
            placement="left"
          >
            <span>
              {/**  TODO - Refactor code removing MUI's Button component and use FormButton instead */}
              <Button
                data-testid="new-heat.ladle-tapping"
                variant='contained'
                color='primary'
                disabled={!plantState.data.general}
                component={Link}
                to={ROUTES_PATH.APPLICATIONS_HEATS_TAPPING_CREATE}
              >
                {t('new-heat.ladle-tapping')}
              </Button>
            </span>
          </CustomTooltip>
          <CustomTooltip
            key='plant-overview-new-heat-ladle-furnace'
            title={ladleFurnaceHeatTooltipMessage}
            placement="left"
          >
            <span>
              {/**  TODO - Refactor code removing MUI's Button component and use FormButton instead */}
              <Button
                data-testid="new-heat.ladle-furnace-mode"
                variant='contained'
                color='primary'
                disabled={!plantState.data.general}
                component={Link}
                to={ROUTES_PATH.APPLICATIONS_HEATS_LADLE_FURNACE_CREATE}
              >
                {t('new-heat.ladle-furnace-mode')}
              </Button>
            </span>
          </CustomTooltip>
        </Footer>
      )}
    </>
  );
};

export default Overview;
